
import React from 'react'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Feedback from '../components/Feedback'
import ErrorBoundary from '../components/ErrorBoundary'

const FrankPepeFeedback = () => {
  return (
    <ErrorBoundary>
      <Helmet title='Frank Pepe Review'/>
      <ToastContainer 
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Feedback source='frank-pepe' />
    </ErrorBoundary>
  )
}

export default FrankPepeFeedback
